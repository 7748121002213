<template>
  <v-simple-table>
    <thead>
      <tr>
        <th v-if="showDate">Date</th>
        <th>Time</th>
        <th>Aircraft</th>
        <th v-if="showCaptain">Captain</th>
        <th v-if="showPOB" title="Persons on Board">PoB</th>
        <th>Destination</th>
        <th v-if="showStatus">Status</th>
        <th v-if="showActions" class="text-right">Actions</th>
      </tr>
    </thead>
    <tbody v-if="items.length">
      <tr v-for="item in items" :key="item.id">
        <td v-if="showDate">
          {{ item.date | formatDate }}
        </td>
        <td>{{ item.time }}</td>
        <td>
          <aircraft-info-popup :item="item" :show-info-icon="showInfoIcons" />
        </td>
        <td v-if="showCaptain">
          <pilot-info-popup :item="item" :show-info-icon="showInfoIcons">
            {{ item.captain_name }}
          </pilot-info-popup>
        </td>
        <td v-if="showPOB">
          <pilot-info-popup :item="item" :show-info-icon="false">
            {{ item.pob }}
          </pilot-info-popup>
        </td>
        <td>
          {{ item.destination }}
          <small v-if="item.destination_data"
            >({{ item.destination_data.code }})</small
          >
        </td>
        <td v-if="showStatus">
          <status-info-popup :item="item" :show-info-icon="showInfoIcons" />
        </td>
        <td v-if="showActions" class="text-right text-no-wrap">
          <v-btn
            v-if="item.status === 'New'"
            class="ml-1"
            color="primary"
            fab
            x-small
            elevation="0"
            title="Mark as Departed"
            @click="markAsDeparted(item)"
          >
            <v-icon>mdi-airplane-takeoff</v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="99" class="text-body-2 text-center py-8">
          No departures found.
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { DateTime } from "luxon";
import AircraftInfoPopup from "./AircraftInfoPopup.vue";
import PilotInfoPopup from "./PilotInfoPopup.vue";
import StatusInfoPopup from "./StatusInfoPopup.vue";

export default {
  name: "DeparturesList",
  props: {
    items: {
      type: Array,
      required: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    showCaptain: {
      type: Boolean,
      default: true,
    },
    showPOB: {
      type: Boolean,
      default: true,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    showInfoIcons: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AircraftInfoPopup,
    PilotInfoPopup,
    StatusInfoPopup,
  },

  methods: {
    markAsDeparted: async function (item) {
      await this.$store.dispatch("departures/markAs", {
        status: "Departed",
        itemId: item.id,
      });
    },
  },

  filters: {
    formatDate: function (value) {
      if (!value) return "";
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
    },
  },
};
</script>
