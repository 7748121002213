<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    nudge-bottom="8"
    max-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
      >
        <slot />
        <v-icon
          v-if="showInfoIcon"
          color="grey"
          small
          style="vertical-align: baseline; opacity: 0.5;"
        >
          mdi-information-outline
        </v-icon>
      </span>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>mdi-account-tie-hat</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="mb-3">
              {{ item.captain_name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.captain_email">
              <v-icon
                small
                class="pe-1"
              >
                mdi-email
              </v-icon>
              <a
                :href="'mailto:' + item.captain_email"
                target="_blank"
              >
                {{ item.captain_email }}
              </a>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.captain_phone">
              <v-icon
                small
                class="pe-1"
              >
                mdi-phone
              </v-icon>
              <a
                :href="'tel:' + item.captain_phone"
                target="_blank"
              >
                {{ item.captain_phone }}
              </a>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="item.pob"
              class="mt-3"
            >
              <v-icon
                small
                class="pe-1"
              >
                mdi-account-multiple
              </v-icon>
              {{ item.pob }} persons on Board
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "PilotInfoPopup",
  props: {
    item: {
      type: Object,
      required: true,
    },
    showInfoIcon: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
