<template>
  <v-card>
    <v-card-title>
      <v-icon class="pe-2">
        mdi-airplane-takeoff
      </v-icon>
      Departures
      <v-spacer />
      <v-btn
        color="primary"
        fab
        x-small
        elevation="0"
        to="/admin/departures/new"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row class="mb-1">
        <v-col>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                elevation="0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  mdi-calendar
                </v-icon>
                {{ filterDateText }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(key, title) in filterDateOptions"
                :key="key"
                @click="filterDateBy(key)"
              >
                <v-list-item-action>{{ title }}</v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <departures-list
        :items="items"
        :show-info-icons="true"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DeparturesList from "../../../components/DeparturesList.vue";
import DatesHelper from "../../../store/dates-helper";

export default {
  components: {
    DeparturesList
  },

  mounted() {
    this.$store.dispatch("departures/subscribe");
  },
  beforeDestroy() {
    this.$store.dispatch("departures/unsubscribe");
  },

  data: function() {
    return {
      filterDateOptions: DatesHelper.filterOptions()
    };
  },

  computed: {
    ...mapGetters({ items: "departures/items" }),
    filterDateText() {
      return this.$store.state.departures.itemsFilter.dateText;
    }
  },

  methods: {
    filterDateBy(type) {
      this.$store.dispatch("departures/filterDateBy", type);
    }
  }
};
</script>
