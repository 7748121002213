<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    nudge-bottom="8"
    max-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
      >
        {{ item.aircraft_reg }}
        <small>({{ item.aircraft_type }}) </small>
        <v-icon
          v-if="showInfoIcon"
          color="grey"
          small
          style="vertical-align: baseline; opacity: 0.5;"
        >
          mdi-information-outline
        </v-icon>
      </span>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>mdi-airplane</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="mb-2">
              {{ item.aircraft_reg }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.aircraft_type">
              <small>Type:</small>
              <span v-if="item.aircraft_data && item.aircraft_data.aircraft_type">
                {{ item.aircraft_data.aircraft_type }}
                <small>({{ item.aircraft_data.aircraft_type }})</small>
              </span>
              <span v-else>
                {{ item.aircraft_type }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.aircraft && item.aircraft.aircraft_class">
              <small>Class:</small> {{ item.aircraft_data.aircraft_class }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.aircraft_data && item.aircraft_data.mtow">
              <small>MTOW:</small> {{ item.aircraft_data.mtow }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "AircraftInfoPopup",
  props: {
    item: {
      type: Object,
      required: true,
    },
    showInfoIcon: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
