<template>
  <div>
    <v-menu
      v-if="hasInfo()"
      :close-on-content-click="false"
      offset-y
      nudge-bottom="8"
      max-width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          {{ item.status }}
          <v-icon
            v-if="showInfoIcon"
            color="grey"
            small
            style="vertical-align: baseline; opacity: 0.5;"
          >
            mdi-information-outline
          </v-icon>
        </span>
      </template>

      <v-card>
        <div
          v-if="hasCancelledInfo()"
          class="pa-6"
        >
          <p class="v-list-item__title mb-2">
            Cancellation Reason
          </p>
          <p class="text-body-2 mb-0">
            {{ item.cancellation_reason }}
          </p>
        </div>
        <div
          v-if="hasRejectedInfo()"
          class="pa-6"
        >
          <p class="v-list-item__title mb-2">
            Rejection Message
          </p>
          <p class="text-body-2 mb-0">
            {{ item.rejection_message }}
          </p>
        </div>
      </v-card>
    </v-menu>
    <span v-else>
      {{ item.status }}
      <sup>
        <v-icon
          v-if="item.status === 'New'"
          color="orange"
          small
        >
          mdi-shimmer
        </v-icon>
      </sup>
    </span>
  </div>
</template>

<script>
export default {
  name: "StatusInfoPopup",
  props: {
    item: {
      type: Object,
      required: true
    },
    showInfoIcon: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    hasInfo: function () {
      return this.hasCancelledInfo() || this.hasRejectedInfo();
    },
    hasCancelledInfo: function () {
      return this.item.status === "Cancelled" && this.item.cancellation_reason;
    },
    hasRejectedInfo: function () {
      return this.item.status === "Rejected" && this.item.rejection_message;
    },
  }
};
</script>
